<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.463 12h10c.283 0 .52-.096.711-.288a.965.965 0 0 0 .288-.712.968.968 0 0 0-.288-.713.967.967 0 0 0-.712-.287h-10a.97.97 0 0 0-.712.287.97.97 0 0 0-.287.713c0 .283.095.52.287.712.192.192.43.288.713.288zm0 4h6a.968.968 0 0 0 .713-.288.967.967 0 0 0 .287-.712.97.97 0 0 0-.287-.713.97.97 0 0 0-.713-.287h-6a.97.97 0 0 0-.713.287.97.97 0 0 0-.287.713c0 .283.095.52.287.712.192.192.43.288.713.288zm-3 4c-.55 0-1.021-.196-1.412-.587A1.927 1.927 0 0 1 2.463 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4.463 4h5.175a1.978 1.978 0 0 1 1.4.575L12.463 6h8c.55 0 1.02.196 1.412.588.392.391.587.862.587 1.412v10c0 .55-.195 1.021-.587 1.413a1.928 1.928 0 0 1-1.413.587h-16z"
      fill="#54718E"
    />
  </svg>
</template>
